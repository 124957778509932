$pageMaxWidth: 970px;
$primaryBackgroundColorDark: #121212;
$backgroundColorDark: #272727;

html {
  overflow-x: hidden;

  @media (prefers-color-scheme: dark) {
    // Make the background color of the page really dark.
    background: $primaryBackgroundColorDark;

    @media (min-width: $pageMaxWidth) {
      // But if the page is bigger than the max width, use the same color as the navigation bar.
      background: $backgroundColorDark;
    }
  }

  body {
    // if we uncomment this, the header won't be sticky anymore
    // https://stackoverflow.com/a/66416972/1663648
    // overflow-x: hidden;

    @media (prefers-color-scheme: dark) {
      // Make the background color of the page really dark.
      background: $primaryBackgroundColorDark;

      @media (min-width: $pageMaxWidth) {
        // But if the page is bigger than the max width, use the same color as the navigation bar.
        background: $backgroundColorDark;
      }
    }

    .date-picker-dialog {
      .MuiDialog-paper {
        // The date picker itself has a min width of 320px
        // without this the dialog will have a horizontal scroller
        min-width: 320px;
        box-shadow: transparent;
      }
    }

    .emoji {
      height: 1em;
      width: 1em;
      vertical-align: -0.1em;
      margin: 0px 0.05em 0px 0.1em;
    }

    a.list-item-link {
      color: inherit;
      text-decoration: none;
    }

    .side-navigation {
      .side-navigation-contents {
        min-width: 250px;
      }
    }

    .settings-list {
      .settings-card {
        width: 100%;
      }
    }

    .train-card {
      width: 100%;

      .station-card-avatar {
        width: 1.5em;
        height: 1.5em;
        font-size: 1.5em;
      }

      .late-chip {
        float: right;
      }
    }

    .train-departure-time-label {
      &.train-late {
        .scheduled-departure-time {
          margin-right: 4px;
          opacity: 0.5;
          text-decoration: line-through;
        }
      }

      &:not(.train-late) {
        .estimated-departure-time {
          display: none;
        }
      }
    }

    .train-stops-stepper {
      .MuiStepConnector-lineVertical {
        min-height: 8px;
      }

      .late-chip {
        float: right;
      }
    }

    #root {
      max-width: $pageMaxWidth;
      margin: 0 auto;

      .navigation-bar {
        @media (min-width: $pageMaxWidth) {
          box-shadow: none;
        }
      }

      .station-selection-form {
        .MuiInput-input {
          padding-right: 12px;
        }
      }

      .loading-indicator {
        position: fixed;
        left: 50%;
        top: 50%;
        margin-left: -40px;
        margin-top: -40px;
      }
    }
  }
}
